import React, { useEffect, useState } from "react";
import "./ModuleSeven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Train from "../../../../Images/Module9/m929.png";
import Rightway from "../../../../Images/Module9/rightway.mp4";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import "./ModuleNine.css";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

export const ModuleNinePart3 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module9_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module9_page1">
            <div className="higway_bg">
              <Container>
                <Row>
                  <Col lg={5} md={7} xs={12}>
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      RECENT LAWS AND HIGHWAY SAFETY CONCERNS
                    </h2>
                    <h3 data-aos="fade-up" data-aos-duration="3000">
                      Module 9 - Part 3
                    </h3>
                  </Col>

                  {/* <img src={Highway} alt="image" className="highway_background"/> */}
                </Row>
              </Container>
            </div>
             
            <div className="rig_of_way">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  Right of Way Rules and Traffic Laws Every Driver Should Know
                </h2>
                <p>
                  This video will discuss important issues about Right of Way.
                  Click the Play button to start the video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Rightway} type="video/mp4" />
                </video>
                <p clsssName="mt-5">
                  <span>TURNING LEFT (VTL-1141)</span>
                  <br />
                  When you're turning left at an intersection or into a
                  driveway, alley, or private road, you must let any oncoming
                  vehicles pass first if they are already in the intersection or
                  approaching closely.
                </p>

                <p>
                  <span>MOVE OVER LAW (VTL-1144-a)</span>
                  <br />
                  If you see an emergency vehicle stopped on the side of the
                  road, you need to either switch lanes or slow down to give
                  them space and avoid any danger.
                </p>

                <p>
                  <span>ENTERING A TRAFFIC CIRCLE (VTL-1145)</span>
                  <br />
                  When you’re approaching a traffic circle or rotary, you must
                  yield to vehicles already in the circle unless a sign tells
                  you otherwise.
                </p>

                <p>
                  <span>PEDESTRIANS IN CROSSWALKS (VTL-1151-a)</span>
                  <br />
                  If traffic lights aren't working, you must slow down or stop
                  for pedestrians crossing the street at a crosswalk unless
                  there's a pedestrian tunnel or bridge.
                </p>
                <h3>TRAIN CROSSINGS (VTL-1170)</h3>

                <p>
                  When approaching a railroad crossing, stop at least 15 feet
                  from the tracks if there's any sign of a train coming.
                </p>

                <h3>VEHICLES STOPPING AT RAILROAD CROSSINGS (VTL-1171)</h3>
                <p>
                  Some vehicles, like buses and trucks carrying hazardous
                  materials, must always stop at railroad crossings, look, and
                  listen for trains, and only cross when it's safe.
                </p>

                <h3>PASSING A SCHOOL BUS (VTL-1174)</h3>
                <p>
                  If a school bus has its red lights flashing, you must stop and
                  wait until the bus starts moving again or the driver signals
                  you to pass.
                </p>

                <h3>BLOCKING AN INTERSECTION (VTL-1175)</h3>
                <p>
                  Never enter an intersection if there isn't enough space on the
                  other side for your vehicle. You shouldn’t block traffic.
                </p>

                <h3>BLOCKING A RAILROAD CROSSING (VTL-1176)</h3>
                <p>
                  When approaching a railroad crossing, make sure there's enough
                  room for your vehicle to completely clear the tracks before
                  crossing. Don’t stop on the tracks.
                </p>

                <div className="train_img">
                  <img src={Train} alt="Image" />
                </div>
              </Container>
            </div>
            <div className="page9_con some_pad">
              <Container>
                <h3>
                  <strong>
                    {" "}
                    CERTAIN VEHICLES MUST STOP AT ALL RAILROAD GRADE CROSSINGS
                    (VTL-1171){" "}
                  </strong>
                </h3>
              </Container>

              <div
                className="train_stop"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <Container>
                  <Row>
                    <Col md={5}>
                      <p>
                        Specific vehicles, including buses, school buses, and
                        those carrying hazardous materials, must stop at
                        railroad grade crossings, listen and look for
                        approaching trains, and proceed only when safe.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="page10_con some_pad">
              <Container>
                <h2>OVERTAKING AND PASSING SCHOOL BUS (VTL-1174)</h2>
                <ul>
                  <li>
                    Motorists encountering a stopped school bus with activated
                    red visual signals must stop until the bus resumes motion or
                    is signaled to proceed.
                  </li>
                  <li>
                    Clarifies terms such as "public highway" and provides
                    context for certain regulations.
                  </li>
                </ul>
                <h2>OBSTRUCTING TRAFFIC AT INTERSECTION (VTL-1175)</h2>
                <ul>
                  <li>
                    Prohibits drivers from obstructing traffic at intersections,
                    especially when insufficient space exists on the opposite
                    side for their vehicle.
                  </li>
                </ul>

                <h2>OBSTRUCTING HIGHWAY-RAILROAD GRADE CROSSINGS (VTL-1176)</h2>
                <ul>
                  <li>
                    Drivers must ensure there is adequate clearance and space on
                    the opposite side of a railroad crossing before proceeding,
                    preventing obstruction.
                  </li>
                </ul>
              </Container>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
