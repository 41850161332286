import React, { useEffect, useState } from "react";
import "./ModuleSeven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import right_of_way from "../../../../Images/Module9/right_of_way.jpg";
import logo from "../../../../Images/Module9/logo.png";
import share_the_road from "../../../../Images/Module9/share_the_road.png";
import age_of_stu from "../../../../Images/Module9/age_of_stu.jpg";
import passing from "../../../../Images/Module9/passing.jpg";
import average from "../../../../Images/Module9/average.jpg";
import consequences from "../../../../Images/Module9/consequences.png";
import fine from "../../../../Images/Module9/fine.png";
import home from "../../../../Images/Module9/Home.png";
import bike1 from "../../../../Images/Module9/bike1.jpg";
import bike2 from "../../../../Images/Module9/bike2.jpg";
import bike3 from "../../../../Images/Module9/bike3.jpg";
import bike4 from "../../../../Images/Module9/bike4.jpg";
import bike5 from "../../../../Images/Module9/bike5.jpg";
import bike6 from "../../../../Images/Module9/bike6.jpg";
import bike7 from "../../../../Images/Module9/bike7.jpg";
import bike8 from "../../../../Images/Module9/bike8.jpg";
import bike9 from "../../../../Images/Module9/bike9.jpg";
import bike10 from "../../../../Images/Module9/bike10.jpg";
import bike11 from "../../../../Images/Module9/bike11.jpg";
import bike12 from "../../../../Images/Module9/bike12.jpg";
import bike_racing from "../../../../Images/Module9/bike_racing.png";
import bus from "../../../../Images/Module9/bus.png";
import sch_bus from "../../../../Images/Module9/sch_bus.png";
import bus1 from "../../../../Images/Module9/bus1.png";
import Bus_safe from "../../../../Images/Module9/m92.png";
import Train from "../../../../Images/Module9/m929.png";
import Rightway from "../../../../Images/Module9/rightway.mp4";
import Newbiker from "../../../../Images/Module9/newbiker.mp4";
import Highway from "../../../../Images/Module9/highway.jpg";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import imagePedestriansAndBicyclistsDifferent from "../../../../Images/Module9/pedestrians-and-bicyclists-are-different-than-other-road-users.png";
import imageVulnerableRoadUsers from "../../../../Images/Module9/pedestrians-and-bicyclists-are-the-most-vulnerable-roadway-users.png";
import imageVisibility from "../../../../Images/Module9/visibility.png";
import imagePedestrianFrequentPlaces from "../../../../Images/Module9/places-that-pedestrians-frequent.png";
import imageUncommonPedestrianPlaces from "../../../../Images/Module9/uncommon-places-that-pedestrians-may-be.png";
import imageSharingRoadWithBicyclists from "../../../../Images/Module9/sharing-the-road-with-bicyclists.png";


import "./ModuleNine.css";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

export const ModuleNinePart1 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module9_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module9_page1">
            <div className="higway_bg">
              <Container>
                <Row>
                  <Col lg={5} md={7} xs={12}>
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      RECENT LAWS AND HIGHWAY SAFETY CONCERNS
                    </h2>
                    <h3 data-aos="fade-up" data-aos-duration="3000">
                      Module 9 - Part 1
                    </h3>
                  </Col>

                  {/* <img src={Highway} alt="image" className="highway_background"/> */}
                </Row>
              </Container>
            </div>
            <div className="highway">
              <Container>
                <hr
                  className="hr_line"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                />
                <h2 data-aos="slide-right" data-aos-duration="1000">
                  SCHOOL BUS SAFETY AWARENESS
                </h2>
                <h3>
                  The New York State Vehicle and Traﬃc Law deﬁnes a school bus
                  as:
                </h3>
                <ul>
                  <Row>
                    <Col md={7} xs={12}>
                      <li data-aos="fade-up" data-aos-duration="1000">
                        Every motor vehicle owned by a public or governmental
                        agency or private school and operated for the
                        transportation of pupils, children of pupils, teachers
                        and other persons acting in a supervisory capacity, to
                        or from school or school activities or privately owned
                        and operated for compensation for the transportation of
                        pupils, children of pupils, teachers and other persons
                        acting in a supervisory capacity to or from school or
                        school activities. (NYS VTL §142)
                      </li>
                    </Col>

                    <Col md={5} xs={12}>
                      <div>
                        <img src={Bus_safe} alt="Image" />
                      </div>
                    </Col>
                  </Row>
                </ul>
                <p>
                  School buses have bigger blind spots, take longer to stop, and
                  need more room to maneuver than a standard vehicle. Buses
                  should be treated diﬀerently than you would treat an
                  average-sized vehicle. It is important for drivers to know how
                  to react to a school bus in operation.
                </p>
                <h3>When you encounter a school bus:</h3>
                <ul>
                  <li data-aos="fade-up" data-aos-duration="1000">
                    <b>Slow down.</b> School buses make frequent stops, so be
                    patient and drive at a reasonable speed. Remember, in
                    addition to picking up and dropping oﬀ students, school
                    buses are required by law to stop at railroad crossings.
                  </li>
                  <li data-aos="fade-up" data-aos-duration="2000">
                    <b>Be alert.</b> Always be aware of children and parents
                    that may be waiting at a school bus stop or perhaps running
                    to catch the bus before it departs.
                  </li>
                  <li data-aos="fade-up" data-aos-duration="3000">
                    Come to a complete stop at least 20 feet away from the bus.
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1000">
                    Be extra careful to look around before moving your vehicle,
                    as children may be walking in front of, behind, or on the
                    side of school buses. Check for pedestrians - especially
                    near schools, bus stops, playgrounds, parks, and behind
                    parked cars.
                  </li>
                </ul>
              </Container>
            </div>

            <div className="youngest some_pad">
              <Container>
                <p>
                  The Governor’s Traﬃc Safety Committee (GTSC) reports that in
                  New York State, 2.3 million children are transported by more
                  than 50,000 school buses annually.
                </p>
                <ul>
                  <li>
                    Laws protect students who are getting on and oﬀ a school bus
                    by making it illegal for drivers to pass a school bus while
                    the school bus is stopped for the purpose of dropping oﬀ or
                    picking up passengers and the red lights on the school bus
                    are ﬂashing, regardless of the direction of approach.
                  </li>
                </ul>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  YOUNGEST STUDENTS AT RISK
                </h2>
                <h3>
                  The NY State Education Department of Pupil Transportation,
                  states that the youngest students are most at risk.
                </h3>
                <p>
                  Children ages 4 to 8 (grades K-3) are most susceptible to a
                  school bus fatality. These children, though they represent
                  less than 35% of the student population, were involved in
                  69%of the fatalities (79 out of 114). Factors that likely
                  contribute to these statistics:
                </p>

                <Row>
                  <Col md={6} xs={12}>
                    <ul>
                      <li>
                        The smaller stature of younger children makes them more
                        diﬃcult for bus drivers and motorists to see.
                      </li>
                    </ul>
                  </Col>

                  <Col md={6} xs={12}>
                    <ul>
                      <li>
                        These younger students are unable to see over or around
                        objects such as parked cars or bushes.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="age_of_studt">
              <Container>
                {/* <h2>AGE OF STUDENT 1960-2017/18 SY</h2> */}
                <img
                  src={age_of_stu}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
              </Container>
            </div>

            <div className="right_of_way">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  RIGHT OF WAY
                </h2>
                <Row>
                  <Col lg={3} md={5} xs={12}>
                    <h3>
                      When a school bus stops and ﬂashes its red lights, traﬃc
                      approaching from either direction must stop before
                      reaching the bus:
                    </h3>
                    <ul data-aos="slide-right" data-aos-duration="3000">
                      <li>On a two-lane road</li>
                      <li>On multi-lane highways</li>
                      <li>On divided highways</li>
                    </ul>
                  </Col>

                  <Col lg={9} md={7} xs={12}>
                    <img
                      src={right_of_way}
                      alt=""
                      data-aos="slide-left"
                      data-aos-duration="3000"
                    />
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="share_the_road">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="1000">
                  SHARE THE ROAD
                </h2>
                <Row>
                  <Col md={6} xs={12}>
                    <img
                      src={share_the_road}
                      alt=""
                      data-aos="slide-right"
                      data-aos-duration="3000"
                    />
                  </Col>

                  <Col md={6} xs={12}>
                    <p data-aos="fade-up" data-aos-duration="1000">
                      <b>Yellow ﬂashing lights</b> mean the bus is preparing to
                      stop to load or unload children. Slow down and prepare to
                      stop your vehicle.
                    </p>
                    <p data-aos="fade-up" data-aos-duration="3000">
                      <b>Red ﬂashing lights</b> mean the bus has stopped and
                      children are getting on or oﬀ. Stop your vehicle and wait
                      until the school bus resumes motion, or until signaled by
                      the driver or police oﬃcer to proceed.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="fatalities some_pad">
              <h2 data-aos="slide-right" data-aos-duration="2000">
                FATALITIES CAUSED BY PASSING MOTORISTS
              </h2>
              <p>
                Fatal crashes involving students who were struck by passing
                motorists typically involved one or more of the following
                factors:
              </p>
              <ul>
                <li>
                  Motorists a tempted to pass the bus, claiming they didn’t have
                  time to wait.
                </li>
                <li>
                  Motorists claimed they couldn’t see the ﬂashing lights because
                  the lights were dirty or because sun, rain, snow, or fog
                  blinded them.
                </li>
                <li>
                  The bus driver waved the car through the red ﬂashing lights,
                  unaware a child was crossing the road at that time. Even if
                  the bus driver waves you ahead, you should still remain
                  vigilant and keep to slower speeds.
                </li>
                <li>
                  The motorist, demonstrating disregard for the law and/or
                  children’s safety, did not stop for the ﬂashing red lights.
                </li>
              </ul>

              <Row>
                <Col md={6} xs={12}>
                  <img
                    src={passing}
                    alt=""
                    data-aos="slide-right"
                    data-aos-duration="2000"
                  />
                  <p>
                    The number of Passing Motorist fatalities has decreased
                    steadily over the last ﬁve decades.
                  </p>
                  <p>
                    However, the number of close calls or near misses continues
                    to plague the school transportation system.
                  </p>
                  <ul>
                    <li>
                      Even when red lights are ﬂashing, motorists sometimes pass
                      on the left or right side of the buses that are stopped to
                      load or unload students.
                    </li>
                  </ul>
                </Col>

                <Col md={6} xs={12}>
                  <img
                    src={average}
                    alt=""
                    data-aos="slide-left"
                    data-aos-duration="2000"
                  />
                  <ul>
                    <li>
                      Of the 27 student pedestrians who were struck and killed
                      by a passing motorist, 4 to 8 year olds represent 71% of
                      the total fatalities.
                    </li>
                    <li>
                      {" "}
                      Over the past ﬁve decades, that number has stayed
                      consistent.
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="consequences">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  CONSEQUENCES
                </h2>
                <p>
                  <strong> Vehicle Operators </strong> - There are legal and
                  ﬁnancial consequences for vehicle operators who pass a school
                  bus while the school bus is stopped for the purpose of
                  dropping oﬀ or picking up passengers and red lights on the
                  school bus are ﬂashing.
                </p>
                <Row>
                  <Col md={8} xs={12}>
                    <ul>
                      <li data-aos="slide-right" data-aos-duration="1000">
                        <b>First conviction –</b> Fine of $250-$400 and/or up to
                        30 days in jail
                      </li>
                      <li data-aos="slide-right" data-aos-duration="2000">
                        <b>Second conviction within 3 years –</b> Fine of
                        $600-$750 and/or up to 180 days in jail
                      </li>
                      <li data-aos="slide-right" data-aos-duration="3000">
                        <b>Third conviction (or more) within 3 years –</b> Fine
                        of $750-$1000 and/or up to 180 days in jail
                      </li>
                    </ul>
                    <h6 data-aos="fade-up" data-aos-duration="2000">
                      *Five points will be added to the operator’s driving
                      record for each conviction.
                    </h6>
                  </Col>

                  <Col md={4} xs={12}>
                    <img
                      src={consequences}
                      alt=""
                      data-aos="fade-down"
                      data-aos-duration="2000"
                    />
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="vehicle_own">
              <Container>
                <Row>
                  <Col md={5} xs={12}>
                    <img
                      src={fine}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </Col>

                  <Col md={7} xs={12}>
                    <p>
                      <b>Vehicle Owners </b>- A law was passed in 2019 that
                      authorizes school districts and municipalities to use
                      stop-arm cameras on school buses to impose penalties on
                      the owners of vehicles which pass a school bus while the
                      school bus is stopped for the purpose of dropping oﬀ or
                      picking up passengers and red lights on the school bus are
                      ﬂashing.
                    </p>
                    <ul>
                      <li data-aos="slide-left" data-aos-duration="1000">
                        <b>First violation</b> - Penalty of $250
                      </li>
                      <li data-aos="slide-left" data-aos-duration="3000">
                        <b>Second violation within 18 months </b>– Penalty of
                        $275
                      </li>
                      <li data-aos="slide-left" data-aos-duration="3000">
                        <b>Third violation (or more) within 18 months</b> –
                        Penalty of $300
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
              <img
                src={home}
                alt=""
                data-aos="zoom-in"
                data-aos-duration="3000"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
