import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../Images/module4/logo.png";
import Search from "../../../../Images/module4/search.png";
import Traffic from "../../../../Images/module4/traffic.png";
import Signal from "../../../../Images/module4/signal.png";
import Execute from "../../../../Images/module4/execute.png";
import Strategyvideo from "../../../../Images/module4/strategy.mp4";
import SEE from "../../../../Images/module4/b11.png";
import Hydro from "../../../../Images/module4/hydro.jpg";
import SkidCar from "../../../../Images/module4/b15.png";
import RearWheel from "../../../../Images/module4/b16.jpg";
import RearSkid from "../../../../Images/module4/b17.jpg";
import FrontSkid from "../../../../Images/module4/b18.jpg";
import Skid from "../../../../Images/module4/skid.mp4";
import Skids from "../../../../Images/module4/skid.jpg";
import SkidsSliderComponent from "./SkidsSliderComponent";
import SkidsSliderComponentCopy from "./SkidsSliderComponentCopy";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFour.css";

export const ModuleFour = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [videoOneStatus, setVideoOneStatus] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  useEffect(() => {
    if (videoOneStatus) {
      sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
    }
  }, [videoOneStatus]);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }

  }, [video]);

  return (
    <div className="module4_course module_font same_width_module mb-md-4">
      <Container>
        <div className="driver_skill">
          <Container>
            <div
              className="driver_skill_content"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="2000"
            >
              <h1>
                <span>skills of</span>a safe driver
              </h1>
              <p>Module 4</p>
            </div>
          </Container>
        </div>

        <div className="ribbon_position">
          <div
            className="ribbon_main"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <div className="ribbon_bg ribbon">
              <h2>s.e.e. strategy</h2>
            </div>
          </div>
        </div>

        <div className="strategy_content mt-5 mb-5 pt-md-3">
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <p>
                  A three-step process used to help analyze the surroundings,
                  anticipate potential problems, and make appropriate judgements
                  to then apply them correctly in varied driving situations.
                </p>

                <div className="strategy_buttons mt-md-4 mb-4">
                  <span className="btn_blue btn">
                    <span>S</span>earch
                  </span>

                  <span className="btn_dark_blue btn_blue btn">
                    <span>E</span>valuate
                  </span>

                  <span className="btn_blue btn">
                    <span>e</span>xecute
                  </span>
                </div>

                <p className="text-center">
                  The S.E.E. strategy helps you improve your decision making!
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="vehicle_search mt-4">
          <div
            className="search_car_img"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <img src={Search} alt="Image" />
          </div>

          <div className="search_content">
            <Row>
              <Col
                lg={{ span: 8, offset: 4 }}
                md={{ span: 8, offset: 5 }}
                xs={12}
                className="pl-lg-4"
              >
                <div className="">
                  <h3>SEARCH</h3>

                  <ul className="p-0">
                    <li>
                      Search aggressively ahead, to the sides and behind your
                      vehicle, to identify factors that could cause increased
                      risk and to avoid potential hazards before they arise.
                    </li>

                    <li>
                      How assertively you search, and how much time and space
                      you have, can eliminate or reduce dangerous situations.
                      Searching provides valuable information to help you make
                      good driving decisions.
                    </li>

                    <li>
                      Check your mirrors frequently and use head checks to
                      monitor blind spots. Focus even more on finding potential
                      escape routes in or around intersections (especially
                      intersections with limited visibility), shopping areas and
                      school or construction zones.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="factor_search mt-md-3">
          <Container>
            <Row>
              <Col md={8} xs={12}>
                <div className="factor_search_content">
                  <h2 className="same_heading">
                    WHAT FACTORS ARE YOUR SEARCHING FOR?
                  </h2>
                  <span className="d-block mb-3">
                    Search for factors such as:
                  </span>

                  <div className="factor_content">
                    <Row>
                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">
                            Oncoming traffic that may turn left in front of you
                          </p>
                        </div>
                      </Col>

                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">
                            Traffic coming from the left and the right
                          </p>
                        </div>
                      </Col>

                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">Traffic approaching from behind</p>
                        </div>
                      </Col>

                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">Hazardous road conditions</p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <p className="mt-3">
                    Be especially alert in areas with limited visibility.
                    Visually busy surroundings can potentially hide you from
                    other drivers.
                  </p>
                </div>
              </Col>

              <div className="factor_search_img">
                <img
                  src={Traffic}
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                  alt="Image"
                />
              </div>
            </Row>
          </Container>
        </div>

        <div className="drive_evaluate mt-md-3">
          <Container>
            <Row>
              <Col xl={10} lg={10} md={12} xs={12}>
                <h3 className="mt-md-0 mt-4">EVALUATE</h3>
                <ul className="p-0">
                  <li>
                    Think about how hazards can interact to create risks for
                    you.
                  </li>
                  <li>
                    Anticipate potential problems and have a plan ready to
                    reduce the risk of situations that may arise.
                  </li>
                  <li>
                    Don’t think of evaluating as ‘guessing’, think of it as
                    reading the situation to minimize risks of collisions.
                  </li>
                </ul>

                <h2 className="mt-xl-5 mt-lg-0 pt-md-5 same_heading">
                  WHAT HAZARDS SHOULD YOU WATCH FOR?
                </h2>
              </Col>

              <div className="drive_evaluate_img">
                <img
                  src={Signal}
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  alt="Image"
                />
              </div>

              <Col xl={12} lg={12} md={12} xs={12}>
                <div className="hazard_watch">
                  <Row>
                    <Col xl={4} lg={4} md={12} xs={12}>
                      <div className="hazard_watch_content">
                        <h3 className="mt-md-0 mt-4">
                          ROADS & SURFACE CHARACTERISTICS
                        </h3>
                        <h4 className="mt-md-0 mt-4">
                          Keep an eye out for potholes, guardrails, bridges,
                          location of telephone poles, streetlights and trees.
                        </h4>
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={12} xs={12}>
                      <div className="hazard_watch_content">
                        <h3 className="mt-md-0 mt-4">
                          Traffic Control Devices
                        </h3>
                        <h4 className="mt-md-0 mt-4">
                          Look for traffic signals, including regulatory signs,
                          warning signs and pavement markings, to help you
                          evaluate circumstances ahead.
                        </h4>
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={12} xs={12}>
                      <div className="hazard_watch_content">
                        <h3 className="mt-md-0 mt-4">
                          Other Vehicles and pedestrians
                        </h3>
                        <h4 className="mt-md-0 mt-4">
                          Other vehicles and pedestrians may move into your path
                          and increase the likelihood of a crash.
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="module_execute mt-5">
          <div
            className="module_execute_img"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <img src={Execute} alt="Image" />
          </div>

          <div className="module_execute_content">
            <Row>
              <Col
                lg={{ span: 7, offset: 5 }}
                md={{ span: 7, offset: 5 }}
                xs={12}
              >
                <div className="">
                  <h3>
                    EXECUTE -{" "}
                    <span> CARRYING OUT YOUR DECISIONS TO MINIMIZE RISK </span>
                  </h3>
                  <p className="mb-2">
                    To create more space and minimize harm from any hazard:
                  </p>
                  <ul className="p-0">
                    <li>
                      Communicate your presence and your intentions with
                      directional signals, lights and/or your horn.
                    </li>

                    <li>
                      Adjust your speed by accelerating, slowing, or stopping.
                    </li>

                    <li>
                      Adjust your position and/or direction by changing lanes,
                      if necessary.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="example_strategy mt-md-5 mb-5 pb-md-5 mt-0">
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <h2 className="same_heading">
                  AN EXAMPLE OF THE S.E.E. STRATEGY
                </h2>

                <p>
                  Sarah is navigating through heavy rush hour traffic on the
                  highway. As she approaches an upcoming exit, she notices
                  congestion ahead and immediately engages the S.E.E. strategy.
                  She begins by searching the road ahead, using her peripheral
                  vision to scan for potential hazards such as merging vehicles,
                  sudden stops, or construction zones. Sarah also checks her
                  mirrors to ensure there are no vehicles in her blind spots
                  before considering any lane changes. After completing her
                  search, Sarah evaluates the traffic patterns, taking into
                  account the behavior of nearby drivers and adjusting her speed
                  to maintain a safe following distance. She carefully assesses
                  the situation, noting any erratic movements or potential risks
                  that may affect her route. Finally, based on her observations,
                  Sarah executes her plan, deciding to remain in her current
                  lane and maintain a steady speed to flow with the traffic. She
                  anticipates upcoming lane changes and exits, preparing to
                  signal and merge when necessary. By diligently applying the
                  S.E.E. strategy, Sarah successfully navigates through the
                  congested traffic, making informed decisions to ensure her
                  safety and the safety of others on the road.
                </p>
                {/* <div className="mb-4">
                  <img src={SEE} alt="Image" width="100%" />
                </div> */}
                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    setVideoOneStatus(true);
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Strategyvideo} type="video/mp4" />
                </video>
                <div className="mt-4">
                  <img src={SEE} alt="Image" width="100%" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  );
};
