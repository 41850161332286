import React, { useEffect, useState } from "react";
import "./ModuleSeven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import bus1 from "../../../../Images/Module9/bus1.png";
import SafetyTechnologySliderComponent from "./SafetyTechnologySliderComponent";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import "./ModuleNine.css";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

export const ModuleNinePart4 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module9_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module9_page1">
          <div className="higway_bg">
              <Container>
                <Row>
                  <Col lg={5} md={7} xs={12}>
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      RECENT LAWS AND HIGHWAY SAFETY CONCERNS
                    </h2>
                    <h3 data-aos="fade-up" data-aos-duration="3000">
                      Module 9 - Part 4
                    </h3>
                  </Col>

                  {/* <img src={Highway} alt="image" className="highway_background"/> */}
                </Row>
              </Container>
            </div>
            <div className="dis_driving">
              <Container>
                <h3 data-aos="slide-right" data-aos-duration="2000">
                  DISTRACTED DRIVING
                </h3>
                <h5>
                  Driving is a full-time responsibility. Your performance as a
                  safe driver deponds on being aware of:
                </h5>
                <ul>
                  <li data-aos="zoom-in" data-aos-duration="1000">
                    Your surroundings
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="2000">
                    Weather and road conditions
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="3000">
                    Your vehicle's condition
                  </li>
                </ul>

                <ul>
                  <li data-aos="zoom-in" data-aos-duration="1000">
                    Other traﬃc and drivers
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="2000">
                    Your own physical and mental condition (are you alert?)
                  </li>
                </ul>

                <p>
                  When you are operating a motor vehicle, you need to minimize
                  anything that would interfere with your ability to concentrate
                  on driving safely. Yours and your passengers' safety depends
                  on you.
                </p>

                <p>
                  New York State has a number of laws that address distracted
                  driving. Use of an electronic handheld device, for instance,
                  can result in ﬁnes of $50 to $450 PLUS add 5 points on your
                  driving record. ”Electronic handheld devices” include (but are
                  not limited to) handheld cell phones, tablets, computers,
                  navigation systems, and gaming devices. The severity of these
                  penalties underscores the danger of distracted driving. But
                  the penalties shouldn’t be the only reason to comply with the
                  law – think about how much risk is involved in activities that
                  distract us from driving.
                </p>
              </Container>
            </div>
            <div className="safe_tech_deve some_pad">
              <Container>
                <p>
                  Anything that would interfere with your ability to focus on
                  the business of safe driving should be avoided whenever
                  possible.
                </p>

                <div
                  className="SafetyTechnologySlider_h5p"
                  id="h5p_content_navigate"
                >
                  <SafetyTechnologySliderComponent totalOuterSlides={6} />
                </div>

                <div className="but_img">
                  <h2 data-aos="slide-right" data-aos-duration="1000">
                    WHAT YOU, AS A DRIVER, MUST BE AWARE OF:
                  </h2>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000">
                      Not all vehicles are equipped with these features. You
                      need to be familiar with your vehicle and its features.
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000">
                      These features may not always work. Depending on
                      conditions, the system may not function well. The lens on
                      a Rearview Camera, for example, can become obstructed by
                      dirt or water – making it diﬃcult to see clearly.
                    </li>
                  </ul>
                  <p>
                    These systems can help you be a safer driver, but they can’t
                    replace you. It’s up to you to be alert and drive
                    defensively.
                  </p>
                </div>
              </Container>
              <img
                src={bus1}
                alt=""
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
