import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container, Card } from "react-bootstrap";
import under_con from "../../../../Images/module5/under_con.png";
import speeding_img from "../../../../Images/module5/speeding_img.png";
import drive from "../../../../Images/module5/drive.png";
import officer from "../../../../Images/module5/officer.png";
import Stop from "../../../../Images/module5/m54.png";
import Yield from "../../../../Images/module5/m55.png";
import Regulation from "../../../../Images/module5/m56.png";
import Warning from "../../../../Images/module5/m57.png";
import Railroad from "../../../../Images/module5/m58.png";
import WorkArea from "../../../../Images/module5/m59.png";
import Users from "../../../../Images/module5/users.mp4";
import LargeVehicle from "../../../../Images/module5/largevehicle.mp4";
import CellPhonesAndSafetyBeltsComponent from "./CellPhonesAndSafetyBeltsComponent";
import Slide1 from "../../../../Images/module5/slide1.jpg";
import Slide2 from "../../../../Images/module5/slide2.jpg";
import Slide3 from "../../../../Images/module5/slide3.jpg";
import Slide4 from "../../../../Images/module5/slide4.jpg";
import Slide5 from "../../../../Images/module5/slide5.jpg";
import Slide6 from "../../../../Images/module5/slide6.jpg";
import Slide7 from "../../../../Images/module5/slide7.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFive.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

const TRAFFIC_SIGNS = [
  {
    header: "STOP SIGN ",
    headerSpan: "(red with white letter)",
    img: Stop,
    details:
      "Come to a full stop, yield the right of way to vehicles and pedestrians in or approaching the intersection. Go when it is safe. You must come to a full stop before the stop line, if there is one.",
  },
  {
    header: "Yield Signs ",
    headerSpan: "(red and white with red letters)",
    img: Yield,
    details:
      "Slow down as you approach the intersection. Prepare to stop and yield the right of way to vehicles and pedestrians in or approaching the intersection. You must come to a full stop at a YIELD sign if traffic conditions require it. Approach with caution and be prepared to stop; otherwise, proceed with care.",
  },
  {
    header: "Regulation signs ",
    headerSpan: "(white with black and/or red letters or symbols)",
    img: Regulation,
    details:
      "These signs give you information about rules for traffic direction, lane use, turning, speed, parking, and other special requirements",
  },
  {
    header: "Warning signs ",
    headerSpan: "(yellow with black letters or sumbols)",
    img: Warning,
    details:
      "you are approaching an especially hazardous location or a place where there is a special rule. Be especially cautions when you see a warning sign.",
  },
  {
    header: "Railroad crossing ",
    headerSpan: "(Yellow with black letters 'RR' and 'X' symbol)",
    img: Railroad,
    details:
      "There is a railroad crossing ahead. you should use caution, and you may have to stop. Most buses and some trucks must stop rail road crossings, even when a train is not approaching and warning lights are not activated.",
  },
  {
    header: "Work area signs ",
    headerSpan: "(orange with black letters or symbols)",
    img: WorkArea,
    details:
      "People are working on or near the roadway, and traffic may be controlled by flag person. A work area speed limit of 35 MPH may be posted.",
  },
];

export const ModuleFivePart3 = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [videoOneStatus, setVideoOneStatus] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  useEffect(() => {
    if (videoOneStatus) {
      sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
    }
  }, [videoOneStatus]);
  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);
  return (
    <div>
      <Container>
        <div className="module5_course same_width_module mt-md-4 mb-md-4">
          <div className="container">
            <div className="mdu5_page1">
              <h2>Module 5 - Part 3</h2>
            </div>
          </div>

          <div className="mdu5_page7">
            <div className="container">
              <h2
                className="w_zone_tit"
                data-aos="slide-right"
                data-aos-duration="3000"
              >
                WORK ZONE SAFETY
              </h2>
              <div className="row">
                <div className="col-md-4">
                  <div className="under_con">
                    <img src={under_con} alt="" srcset="" />
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="work_zone">
                    <h3>Definition of a "Work Zone"</h3>
                    <p className="pl-md-4">
                      <span>
                        {" "}
                        AS PER THE VEHICLE AND TRAFFIC LAW (SECTION 160):
                      </span>{" "}
                      <br /> A work zone is a "part of the highway being used or
                      occupied for the conduct of highway work, within which
                      workers, vehicles, equipment, materials, supplies,
                      excavations or other obstructions are present."
                    </p>
                  </div>
                </div>
              </div>

              <div className="safe_zone">
                <h2 data-aos="slide-left" data-aos-duration="3000">
                  BEING SAFE IN A WORK ZONE
                </h2>
                <p className="more_w">
                  More work zones are constantly set up each year. More work
                  zones = higher risk of accidents and deaths.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="safe_box"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <p>
                        669 people died nationally in work zones in the year
                        2014, per the US Department of Transportation.
                      </p>
                    </div>

                    <div
                      className="safe_box"
                      data-aos="slide-right"
                      data-aos-duration="3000"
                    >
                      <p>Speeding ticket fines are doubled in work zones.</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="safe_box"
                      data-aos="slide-left"
                      data-aos-duration="1000"
                    >
                      <p>
                        The most common crash in a highway work zone is the
                        rear-end collision (Federal Highway Administration).
                      </p>
                    </div>
                    <div
                      className="safe_box"
                      data-aos="slide-left"
                      data-aos-duration="3000"
                    >
                      <p>
                        Enforcement of traffic laws in work zones is maintained
                        24 hours a day; work zone speed limits are enforced even
                        when no work is underway.
                      </p>
                    </div>
                  </div>
                </div>
                <p className="more_w">
                  Traffic enforcement is enhanced in work zones because of all
                  the potential risks and dangers.
                </p>
              </div>

              <div className="determine">
                <h2 data-aos="slide-up" data-aos-duration="3000">
                  HOW DO WE DETERMINE A SAFE FOLLOWING DISTANCE BETWEEN
                  VEHICLES?
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="determine_box"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <p>
                        You should stay at least two seconds behind the vehicle
                        that is directly in front of you.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="determine_box"
                      data-aos="slide-left"
                      data-aos-duration="1000"
                    >
                      <p>
                        If you are driving in rain or other adverse conditions,
                        you may need to double or triple your following
                        distance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="determine_box"
                      data-aos="slide-up"
                      data-aos-duration="1000"
                    >
                      <p>
                        A safe following distance is sometimes defined using a
                        formula; one car length per each 10 miles per hour that
                        you are traveling.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="twoSec_rule">
                  <div className="row">
                    <div className="col-md-6">
                      <Card className="speeding_con QuizCollapse">
                        <h6 className="font-bold">Two-second rule</h6>
                        <Card.Body className="timepara">
                          <p>
                            To practice the two-second rule, choose a stationary
                            object on or alongside the road. When the rear
                            bumper of the vehicle ahead of you reaches the
                            object, begin to count “one-thousand-one,
                            one-thousand-two.” If you complete counting to
                            “one-thousand-two” before passing the stationary
                            object, your following distance is adequate.
                          </p>
                        </Card.Body>
                      </Card>

                      <Card className="speeding_con QuizCollapse">
                        <h6 className="font-bold">Covering the brake</h6>
                        <Card.Body className="timepara">
                          <p>
                            The practice of covering the brake is accomplished
                            by placing your foot just above the brake pedal.
                            This is done to shorten one’s reaction time, which
                            might be necessary in situations such as approaching
                            children that are playing near the road or
                            approaching a stale green light. A stale green light
                            is one that is green when you first see it, and may
                            turn to yellow at any time.
                          </p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-md-6">
                      <div className="speeding_con">
                        <h6 className="font-bold">
                          Speeding influences vision, stopping distance, and
                          crash survival as:
                        </h6>
                        <p>Peripheral vision decreases</p>
                        <p>Stopping distance increases</p>
                        <p>The force of impact increases</p>
                        <p>Chances of survival decrease</p>
                        <img
                          src={speeding_img}
                          alt=""
                          srcset=""
                          data-aos="slide-left"
                          data-aos-duration="3000"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mdu5_page8">
            <div className="container">
              <div className="way_user">
                <h2 data-aos="slide-up" data-aos-duration="3000">
                  WHO ARE OTHER HIGHWAY USERS AND HOW DO WE SAFELY
                  <br />
                  SHARE THE ROAD WITH THEM?
                </h2>

                <p className="video_highway_para">
                  The following video will show who the other highway users are
                  and explain how we, as drivers, can safely share the road with
                  them. Click the Play button to start the video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    setVideoOneStatus(true);
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Users} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

        </div>
      </Container>
    </div>
  );
};
