import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container, Card, Accordion } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import rules from "../../../../Images/module5/rules.png";
import rules1 from "../../../../Images/module5/rules1.jpg";
import terse_img from "../../../../Images/module5/terse_img.png";
import dri_exp from "../../../../Images/module5/dri_exp.png";
import way_strt from "../../../../Images/module5/way_strt.png";
import scl_bus from "../../../../Images/module5/scl_bus.png";
import traffic_light from "../../../../Images/module5/traffic_light.jpg";
import flashing_light from "../../../../Images/module5/flashing_light.png";
import traffic_notwor from "../../../../Images/module5/traffic_notwor.png";
import traffice_officer from "../../../../Images/module5/traffice_officer.png";
import Man from "../../../../Images/module5/officer1.png";
import Stop from "../../../../Images/module5/m54.png";
import Yield from "../../../../Images/module5/m55.png";
import Regulation from "../../../../Images/module5/m56.png";
import Warning from "../../../../Images/module5/m57.png";
import Railroad from "../../../../Images/module5/m58.png";
import WorkArea from "../../../../Images/module5/m59.png";
import LargeVehicle from "../../../../Images/module5/largevehicle.mp4";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFive.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";
import TaskList from "./TaskList";

const TRAFFIC_SIGNS = [
  {
    header: "STOP SIGN ",
    headerSpan: "(red with white letter)",
    img: Stop,
    details:
      "Come to a full stop, yield the right of way to vehicles and pedestrians in or approaching the intersection. Go when it is safe. You must come to a full stop before the stop line, if there is one.",
  },
  {
    header: "Yield Signs ",
    headerSpan: "(red and white with red letters)",
    img: Yield,
    details:
      "Slow down as you approach the intersection. Prepare to stop and yield the right of way to vehicles and pedestrians in or approaching the intersection. You must come to a full stop at a YIELD sign if traffic conditions require it. Approach with caution and be prepared to stop; otherwise, proceed with care.",
  },
  {
    header: "Regulation signs ",
    headerSpan: "(white with black and/or red letters or symbols)",
    img: Regulation,
    details:
      "These signs give you information about rules for traffic direction, lane use, turning, speed, parking, and other special requirements",
  },
  {
    header: "Warning signs ",
    headerSpan: "(yellow with black letters or sumbols)",
    img: Warning,
    details:
      "you are approaching an especially hazardous location or a place where there is a special rule. Be especially cautions when you see a warning sign.",
  },
  {
    header: "Railroad crossing ",
    headerSpan: "(Yellow with black letters 'RR' and 'X' symbol)",
    img: Railroad,
    details:
      "There is a railroad crossing ahead. you should use caution, and you may have to stop. Most buses and some trucks must stop rail road crossings, even when a train is not approaching and warning lights are not activated.",
  },
  {
    header: "Work area signs ",
    headerSpan: "(orange with black letters or symbols)",
    img: WorkArea,
    details:
      "People are working on or near the roadway, and traffic may be controlled by flag person. A work area speed limit of 35 MPH may be posted.",
  },
];

export const ModuleFivePart2 = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <Container>
        <div className="module5_course same_width_module mt-md-4 mb-md-4">
          <div className="container">
            <div className="mdu5_page1">
              <h2>Module 5 - Part 2</h2>
            </div>
          </div>

          <div className="mdu5_page5">
            <div className="container">
              <div className="tra_light">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  TRAFFIC SIGNS
                </h2>
                <p>
                  Traffic signs tell you about traffic rules, special hazards,
                  where you are, how to get where you’re going and where
                  services are available.
                </p>

                <p className="traffic_color_bg">
                  The color of traffic signs gives clues to the type of
                  information they provide:
                </p>

                <Row className="mt-5 mb-5 tra_signs">
                  <Col md={4} xs={12}>
                    <div className="man_light_img">
                      <img src={Man} alt="Image" />
                    </div>
                  </Col>

                  <Col md={8} xs={12}>
                    {TRAFFIC_SIGNS.map((r, i) => (
                      <Row key={i} className="tra_sign_block">
                        <Col md={3} xs={3}>
                          <div className="sign_img">
                            <img src={r.img} alt="Image" width="80%" />
                          </div>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>
                            <div className="tra_sign_header">
                              <span>{r.header}</span>
                              {r.headerSpan}
                            </div>
                            {r.details}
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </div>
            </div>

            <div class="h5p-iframe module-5-part-1" id="h5p_content_navigate">
              <h3>
                Note - Match the Signs with the Correct Descriptions. Click on
                “Check Answer” once you are done.
              </h3>
              <TaskList />
            </div>
          </div>

          <div className="mdu5_page5">
            <div className="container">
              <div className="tra_light">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  TRAFFIC LIGHTS
                </h2>
                <p>
                  Traffic lights are usually red, yellow and green from top to
                  bottom, or left to right.
                </p>
                <p>
                  At some intersections, there are single red, yellow or green
                  lights.
                </p>
                <p>Some traffic lights are steady, others flash.</p>
                <p>Some are circular, and some are arrows.</p>
                <p>Here are what various traffic lights mean:</p>
              </div>
            </div>

            <div className="maintra_Div">
              <div className="row">
                <div className="col-md-5">
                  <div className="steady_red">
                    <h2 data-aos="slide-down" data-aos-duration="3000">
                      STEADY RED
                    </h2>
                    <p>Stop. Do not go until the light is green.</p>
                    <p>Unless prohibited, you may make a:</p>
                    <p>Right turn on red, or</p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="tra_lig_img">
                    <img
                      src={traffic_light}
                      alt=""
                      // srcset=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="tra_para">
                    <p>
                      Left turn on red ONLY from a one-way street to another
                      one-way street.
                    </p>
                    <p>
                      Before you make any turn on red, you must come to a full
                      stop and yield the right of way to oncoming traffic and
                      pedestrians.
                    </p>
                    <p>
                      You may not make a turn at a red light if there is a NO
                      TURN ON RED sign posted, or if another sign, signal or
                      pavement marking prohibits the turn. Also, turning on a
                      red light is not allowed in New York City unless a sign is
                      posted permitting it. The driver of a school bus carrying
                      pupils may not turn on any red light. Always watch for
                      pedestrians in the crosswalks when turning on red.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flashing_div">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="law_traffic">
                      <img src={flashing_light} alt="" srcset="" />
                    </div>
                  </div>

                  <div className="col-md-8">
                    <p>
                      <span> FLASHING RED:</span> Means the same as a STOP sign:
                      Stop, yield the right of way, and go when it is safe.
                    </p>

                    <p>
                      <span>RED ARROW</span>: Do not go in the direction of the
                      arrow until the red arrow goes out and a green light or
                      arrow goes on. A right or left turn on red is not
                      permitted at a red arrow.
                    </p>
                    <p>
                      <span>STEADY YELLOW: </span> The light is changing from
                      green to red. Be ready to stop.
                    </p>
                    <p>
                      <span>FLASHING YELLOW: </span> Drive with caution.
                    </p>
                    <p>
                      <span>YELLOW ARROW: </span> The protection of a green
                      arrow is ending. Be prepared to stop.
                    </p>
                    <p>
                      <span>STEADY GREEN: </span> Go, but yield the right of way
                      to other traffic.
                    </p>
                    <p>
                      <span>GREEN ARROW: </span> You may go in the direction of
                      the arrow, but you must yield the right of way to other
                      traffic at the intersection as required by law.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mdu5_page6">
            <div className="container not_wor">
              <h3>What happens if the traffic lights are not working?</h3>
              <p>
                State law requires that, if the traffic lights or controls are
                out of service/malfunctioning when you approach an intersection,
                you must come to a stop as you would for a stop sign. You must
                then proceed according to the rules of right of way, unless you
                are directed to proceed by a traffic officer.
              </p>
            </div>
            <img
              src={traffic_notwor}
              alt=""
              srcset=""
              data-aos="slide-left"
              data-aos-duration="3000"
            />
            <div className="container mt-5">
              <h3>Pavement Markings</h3>
              <p>
                Lines and symbols on the roadway divide lanes and inform you of
                when you may pass other vehicles/change lanes, of which lanes to
                use for turns, and of where you must stop for signs/traffic
                signals.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="1000">
                SINGLE BROKEN LINE:
              </h4>
              <p>
                You may pass other vehicles or change lanes, if you can do so
                safely and not interfere with traffic.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="2000">
                SOLID LINE WITH BROKEN LINE:
              </h4>
              <p>
                If you’re on the side with the solid line, you may not pass
                other vehicles or cross the line except to make a left turn into
                a driveway. If you're on the side with the broken line, you may
                pass if it is safe to do so while not interfering with traffic.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="3000">
                DOUBLE SOLID LINES:
              </h4>
              <p>You may not pass, or change lanes.</p>
              <h4 data-aos="slide-right" data-aos-duration="1000">
                SINGLE SOLID LINE:
              </h4>
              <p>
                You may pass other vehicles or change lanes, but you should do
                so only if obstructions in the road make it necessary, or
                traffic conditions require it.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="2000">
                STOP AND CROSSWALK LINES:
              </h4>
              <p>
                When required to stop because of a sign or light, you must stop
                before reaching the stop line, if there is one. If there is not,
                then you must stop before reaching the crosswalk.
              </p>
              <h3>Traffic Officers</h3>
              <p>
                Directions given by traffic officers take precedence over signs,
                signals or pavement markings. Among those authorized to direct
                traffic are police officers, peace officers (such as on-duty
                auxiliary or fire police), and highway work area flag persons.
              </p>
            </div>
            <div className="tra_officer">
              <img
                src={traffice_officer}
                alt=""
                srcset=""
                data-aos="zoom-in"
                data-aos-duration="3000"
              />
            </div>
          </div>

        </div>
      </Container>
    </div>
  );
};
