import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alcohol from "../../../../Images/module8/alcohol.png";
import Drugs from "../../../../Images/module8/drugs.png";
import DriveAlcohol from "../../../../Images/module8/driveacohol.png";
import Physical from "../../../../Images/module8/physical.png";
import Suspension from "../../../../Images/module8/suspension.png";
import Law from "../../../../Images/module8/law.png";
import Pound from "../../../../Images/module8/pound.png";
import PersonCar from "../../../../Images/module8/person_car.png";
import Risk from "../../../../Images/module8/risk_drug.png";
import ProvisionLaw from "../../../../Images/module8/provision.png";
import LawEffect from "../../../../Images/module8/law_effect.png";
import Leandra from "../../../../Images/module8/leandra.mp4";
import Chemical from "../../../../Images/module8/m8p1-chemical-test.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleEightPart1 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const [showText, setShowText] = useState(false);


  const onPlayerInitialized = () => {
    setLoading(false);
  };


  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
      <Container>
        <div className="module_drugs">
          <Container>
            <div
              className="drugs_content"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <p className="mb-1">Module 8 - Part 1</p>
              <h1 className="mb-0">
                ALCOHOL, <br />
                OTHER DRUGS <br />& DRIVING
              </h1>
            </div>
          </Container>
        </div>

        <div className="overview_content mb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              OVERVIEW
            </h2>

            <p>
              Drinking and driving is a significant problem in the United States
              and a major contributor to the country's highway death toll.
              According to police records, nearly half of all motor
              vehicle-related deaths involve alcohol. This means that either the
              driver, a passenger, or someone else, such as a pedestrian, had
              been drinking at the time of the accident. In most cases, these
              deaths are the result of someone who was operating a vehicle while
              under the influence of alcohol.{" "}
            </p>
            <p>
              {" "}
              On a national level, the statistics are staggering. Every year,
              over 17,000 motor vehicle-related deaths occur as a result of
              alcohol consumption. Additionally, hundreds of thousands of people
              are injured in alcohol-related accidents each year. This is truly
              a national tragedy that affects families and communities across
              the country.{" "}
            </p>
            <p>
              {" "}
              It's important to note that drinking and driving not only poses a
              risk to the driver but also to the passengers, other drivers,
              pedestrians and anyone else who may be on the road. Moreover, the
              consequences of a DUI conviction can be severe and long-lasting,
              including fines, jail time, and a criminal record that can affect
              future employment and other opportunities.{" "}
            </p>
          </Container>
        </div>

        <div className="alcohol_drugs">
          <h2
            className="same_alcohol_head"
            data-aos="zoom-in-right"
            data-aos-duration="2000"
          >
            WHAT ARE "DRUGS" AND "ALCOHOL"?
          </h2>

          <Container>
            <Row>
              <Col lg={6} md={12} xs={12}>
                <div className="alcohol_div mt-3">
                  <h6>WHAT IS ALCOHOL?</h6>
                  <Row>
                    <Col md={8} xs={12}>
                      <p>
                        Alcohol is an odorless, colorless, mind altering
                        (depressant) drug, which, when in beverage form, is
                        contained primarily in beer, wine and distilled spirits.
                      </p>
                    </Col>

                    <Col md={4} xs={12}>
                      <div
                        className="alcohol_img"
                        data-aos="zoom-in-up"
                        data-aos-duration="2000"
                      >
                        <img src={Alcohol} alt="Image" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <div className="alcohol_div mt-3">
                  <h6>WHAT ARE DRUGS?</h6>

                  <Row>
                    <Col md={10} xs={12}>
                      <ul className="p-0">
                        <li>
                          A drug is any substance that alters normal bodily
                          function when absorbed by the body.
                        </li>

                        <li>Drugs come in various forms and strengths.</li>
                      </ul>
                    </Col>

                    <div
                      className="drug_img"
                      data-aos="zoom-in-left"
                      data-aos-duration="2000"
                    >
                      <img src={Drugs} alt="Image" />
                    </div>
                  </Row>
                </div>
              </Col>

              <div className="border_div_bottom"></div>

              <Col md={12} xs={12}>
                <div className="drive_alcohol">
                  <h6>
                    WHY DO PEOPLE DRIVE UNDER THE INFLUENCE OF ALCOHOL AND
                    DRUGS?
                  </h6>

                  <p>
                    The following are just a few of the reasons why people drink
                    or use drugs and drive:
                  </p>

                  <ul className="p-0">
                    <li data-aos="fade-right" data-aos-duration="2000">
                      They believe the risk is minimal. Example: An example of a
                      person who believes the risk is minimal is a college
                      student who has been drinking at a party and feels
                      confident to drive home because they have "only had a few
                      drinks."
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They feel that "crashes only happen to others." Example:
                      An example of someone who feels that "crashes only happen
                      to others" is a person who has never been in an accident
                      while driving under the influence and therefore sees no
                      reason to change their behavior.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have no concern for others; "I don't care" attitude.
                      Example: A person who has an "I don't care" attitude
                      towards the safety of others might drive after drinking or
                      using drugs because they prioritize their own convenience
                      over the safety of others on the road.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They believe they'll be okay if they're driving a short
                      distance. Example: Someone who believes they should be
                      okay to drive only a short distance might be a person who
                      drinks at a bar and only lives a few blocks away, so they
                      feel comfortable driving home.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They believe that being extra careful will compensate for
                      being under the influence. Example: A person who believes
                      that being extra careful should compensate for their
                      impairment may think that they can drive safely despite
                      being under the influence because they are taking extra
                      precautions such as driving slower or focusing harder.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have a self-destructive attitude. Example: A person
                      with a self-destructive attitude might drive under the
                      influence because they have a tendency to engage in risky
                      behaviors and don't care about the consequences.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have the perception that alcohol and/or drugs
                      actually enhance their driving abilities. Example: An
                      example of someone who perceives that alcohol or drugs
                      enhance their abilities is a person who is a nervous
                      driver and drinks before getting behind the wheel because
                      they believe it will calm their nerves and make them a
                      better driver.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have a show-off attitude; "I can do it when others
                      can't." Example: A person with a show-off attitude may
                      drive under the influence to prove to their friends or
                      peers that they can handle their alcohol or drugs and
                      still drive safely.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They use a minimal amount of alcohol, in order to mask a
                      drug high; this improves chances of avoiding detection at
                      potential sobriety checkpoints. Example: A person who uses
                      a minimal amount of alcohol to mask a drug high might be a
                      person who uses marijuana but drinks just enough alcohol
                      to mask the odor and avoid detection at sobriety
                      checkpoints.
                    </li>

                    <li
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="overlap_content"
                    >
                      Their judgement is impaired by the substance in their
                      system. Example: A person whose judgment is impaired by a
                      substance might drive under the influence because they are
                      not able to make sound decisions and assess the risks of
                      driving while impaired.
                    </li>

                    <li
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="overlap_content"
                    >
                      They are addicted to a type of drug, including alcohol.
                      Example: An example of someone who is addicted to drugs or
                      alcohol might be a person who has a history of substance
                      abuse and finds themselves unable to stop drinking or
                      using drugs even when they know it's dangerous to drive.
                    </li>

                    <li
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="overlap_content"
                    >
                      They are a problem drinker and have developed a tolerance.
                      Example: A problem drinker who has developed a tolerance
                      might drive under the influence because they can handle
                      more alcohol than the average person and may not feel as
                      impaired as they actually are.
                    </li>
                  </ul>
                </div>

                <div
                  className="drive_alcohol_img"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <img src={DriveAlcohol} alt="Image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="drug_use">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE PHYSICAL & PHYSIOLOGICAL EFFECTS OF ALCOHOL & DRUG USE
            </h2>
            <p className="physical_para">
              ALCOHOL-IMPAIRED DRIVERS MAY DISPLAY THE FOLLOWING
              CHARACTERISTICS:
            </p>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>Mild neurosis, or anxieties and phobias</li>

                  <li>Low tolerance of tension and frustration</li>

                  <li>Reduced inhibitions and greater risk taking</li>

                  <li>Immaturity</li>

                  <li>Enhanced emotions</li>

                  <li>Inhibition of the decision-making process</li>
                </ul>
                <p className="physical_para">
                  The physical and psychological impairments caused by consuming
                  one and one half to two ounces of alcohol (two beers, two
                  glasses of wine, two shots distilled spirits) in an hour may
                  be the following:
                </p>
                <ul className="p-0">
                  <li>Peripheral vision decreases</li>

                  <li>Eye reaction decreases</li>

                  <li>
                    Visual acuity decreased by an amount comparable to wearing
                    dark glasses at night
                  </li>

                  <li>
                    Recovery time from headlight glare is longer, from 7 to 32
                    seconds depending upon the individual
                  </li>

                  <li>Complex reaction time increases 15 to 25 percent</li>

                  <li>Judgment as to distance and speed is less accurate</li>
                  <li>Attention to details is lacking</li>
                  <li>Driver talks more</li>
                  <li>A false increase in self-confidence </li>
                  <li>The moral code may be temporarily decreased</li>
                  <li>Double vision occurs</li>
                  <li>Blurring of vision occurs</li>
                  <li>
                    Nystagmus (rapid involuntary oscillation of the eyes) occurs
                  </li>
                  <li>Night vision impaired </li>
                  <li>Impairment of stereopsis (three-dimensional vision) </li>
                  <li>Visual hallucinations occur</li>
                  <li>Dizziness occurs </li>
                  <li>Feelings of drowsiness and fatigue enhanced</li>
                </ul>
                <p className="drug_effect">
                  Many drugs, such as depressants, have effects very similar to
                  alcohol.
                </p>

                <p className="note_drug">
                  Note: Alcohol also impairs pedestrians; their behavior is
                  often unpredictable. Be particularly attentive after sporting
                  events and near taverns.
                </p>
              </Col>

              <Col md={6} xs={12}>
                <div
                  className="physical_img"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                >
                  <img src={Physical} alt="Image" />
                </div>
              </Col>
            </Row>

            <p className="physical_para">
              DEFINING AND CALCULATING BLOOD ALCOHOL CONTENT (BAC)
            </p>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>
                    Accurately measuring a person’s Blood Alcohol Content (BAC)
                    is achieved by administering a blood test, urine test,
                    saliva test or breath test. Individuals can roughly
                    calculate their own BAC by knowing the following facts:
                  </li>

                  <li>
                    An average drink (12 ounces of beer, 5 ounces of wine, 1
                    ounce of distilled spirits) will produce a BAC of .020
                    percent in a 160-pound person (.030 percent in a 110-pound
                    person, .015 percent in a 220-pound person).
                  </li>
                </ul>
              </Col>

              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>
                    Once the alcohol is in the bloodstream, all people,
                    regardless of weight, will metabolize or burn up from .015
                    percent to .020 percent alcohol in approximately one hour
                    (about one drink for a 160-pound person). This can decrease
                    with the onset of middle age.
                  </li>

                  <li>
                    Alcohol takes from approximately one-quarter to
                    three-quarters of an hour to permeate the stomach wall and
                    enter the bloodstream.
                  </li>
                </ul>
              </Col>
            </Row>

            <p className="physical_para">
              BEVERAGES COTAINING ALCHOHOL THAT ARE CONSIDERED TO EQUAL 1 DRINK
              ARE:
            </p>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>A (12 oz) can of beer with an alcohol content of 4.5%</li>

                  <li>
                    A (12 oz) bottle of wine cooler with an alcohol content of
                    6%
                  </li>
                </ul>
              </Col>

              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>A (5 oz) glass of wine with an alcohol content of 12%</li>

                  <li>
                    A (1 oz) shot of distilled spirits with an alcohol content
                    of 45%
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="person_example">
          <h2 data-aos="zoom-in-right" data-aos-duration="2000">
            HERE IS AN EXAMPLE OF THE BAC OF A 160-POUND PERSON WHO BEGINS
            DRINKING AT 5:00PM:
          </h2>
          <Row>
            <Col xs={12}>
              <ul className="p-0">
                <li>
                  Approximate blood alcohol content at 6:00 equals .08 percent
                  minus approximately .01 percent that was metabolized, and
                  perhaps .01 percent still in the stomach. Therefore, BAC is
                  approximately .06 percent.
                </li>

                <li>
                  Approximate BAC at 6:30pm equals .08 percent minus
                  approximately .02 percent metabolized. Therefore, BAC is
                  approximately .06 percent.
                </li>
                <li>
                  Approximate BAC at 7:00pm equals .08 percent minus
                  approximately .03 percent metabolized. Therefore, BAC is still
                  approximately .05 percent.
                </li>
              </ul>
            </Col>
          </Row>
          <p className="note_drug">
            NOTE: These are estimates. There can be errors in estimating BAC. It
            is possible to have two people of equal weight with equal food
            amounts in their stomachs to have BACs that differ by .02 percent.
          </p>
          <p className="note_drug">
            NOTE: Zero tolerance is defined as any measurable amount (.02 and
            above) of alcohol in the blood, breath or urine of a driver.{" "}
          </p>
          <Container>
            <div className="mt-3 mb-5">
              <Table responsive className="text-center" cellspacing="5">
                <thead>
                  <tr>
                    <th>BEVERAGE</th>
                    <th className="odd_color">BAC/DRINK</th>
                    <th>time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="data_normal">12 ounces of beer</td>
                    <td className="odd_color">.02%</td>
                    <td>5:00-5:10pm</td>
                  </tr>
                  <tr>
                    <td className="data_normal">+12 ounces of beer</td>
                    <td className="odd_color">+.02%</td>
                    <td>5:10-5:25pm</td>
                  </tr>
                  <tr>
                    <td className="data_normal">+12 ounces of beer</td>
                    <td className="odd_color">+.02%</td>
                    <td>5:25-5:40pm</td>
                  </tr>

                  <tr>
                    <td className="data_normal">+12 ounces of beer</td>
                    <td className="odd_color">+.02%</td>
                    <td>5:40-5:55pm</td>
                  </tr>
                </tbody>
              </Table>

              <div className="pound_img pb-2">
                <img
                  src={Pound}
                  className="image1"
                  alt="Image"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
                <img
                  src={PersonCar}
                  className="image2"
                  alt="Image"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </Container>
        </div>
      </Container>
    </div>
  );
};
