import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../Images/logo.png";
import Navigate from "../../../../Images/module0/navigate.jpg";
import Course from "../../../../Images/module0/course1.png";
import Border from "../../../../Images/module0/border.png";
import White_Border from "../../../../Images/module0/white_line.png";
// import Auto from "../../../../Images/module0/auto.png";
import Auto from "../../../../Images/auto_play.svg";
import Exercise from "../../../../Images/module0/exercise.png";
import Arrow_Navigate from "../../../../Images/module0/arrow_down.png";
// import Start from "../../../../Images/module0/start.png";
import Video from "../../../../Images/module0/video.png";
import Dropdown from "../../../../Images/module0/dropdown.png";
import Car from "../../../../Images/module0/car.png";
import downloadblue from "../../../../Images/downloadblue.svg";
import audio_play from "../../../../Images/auto_play.svg";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";
import {Button} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleZero.css";

export const ModuleZero = () => {
  const [scroll, setScroll] = useState(false);

  // const contentService = new ContentService("/h5p");
  // const h5pPlayer = React.useRef(H5PPlayerUI);

  // const [loading, setLoading] = useState(true);

  // const onPlayerInitialized = () => {
  //   setLoading(false);
  // };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="module0_course module_font same_width_module mb-md-4 pt-3">
      <Container>
        <div className="navigate_online">
          <Container>
            <div className="online_course_content">
              <div className="navigate_img">
                <img src={Navigate} alt="Image" />
                {/* <img
                  src={Logo}
                  className="logo_module"
                  alt="Image"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="2000"
                /> */}
                <h1>
                  {" "}
                  NAVIGATING THE ONLINE
                  <span> PRE-LICENSING COURSE </span>
                </h1>
              </div>

              <div className="main_content mt-5">
                <h2
                  className="welcome"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  WELCOME!
                </h2>
                <p>
                  Welcome to the Online 5 Hour Pre-Licensing Course for the
                  state of New York. Throughout the duration of the course, you
                  will gain more knowledge on how to keep you and others safe on
                  the road. You will learn the basic rules of the road, the
                  dangers of driving while distracted or under the influence, as
                  well as other important skills and habits that will help
                  encourage you to drive safely.
                </p>

                <Row className="mt-md-5 mt-3">
                  <Col md={7} xs={12}>
                    <h2 data-aos="zoom-in-right" data-aos-duration="2000">
                      WHO SHOULD TAKE THIS COURSE?
                    </h2>
                    <p>
                      This course meets the New York State DMV requirements for
                      prospective new drivers, age 18 years and older, who want
                      to schedule a road test and obtain a NY Driver’s License.
                    </p>
                  </Col>

                  <Col md={5} xs={12}>
                    <div className="course_img">
                      <img src={Course} alt="Image" />
                    </div>
                  </Col>
                </Row>

                <div className="mt-md-5 mb-md-5 border_width_mob">
                  <img src={Border} alt="Image" />
                </div>

                <h2
                  className="mt-4 navigation_ins"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  NAVIGATION INSTRUCTIONS
                </h2>
                <p>
                  This course is designed to display responsively based on the
                  device that you are using. Each module is designed to be
                  viewed from top to bottom. You should scroll through the
                  content in a way that will allow you to understand the
                  materials presented.
                </p>
                <p>
                  There will be interactive elements throughout the modules, to
                  help you engage with the material. We require you to complete
                  each interactive element as it is presented to you and before
                  continuing to the next section of the module. The course will
                  include the following multimedia elements:
                </p>

                <div className="audio_video mt-4">
                  <h4>audio</h4>
                  <p>
                    An audio narration guide will be available in some modules
                    of the course to help guide you through the course. To play
                    the audio for a module, click on the play button on the top
                    of the module. The button will look like this:
                  </p>

                  <div className="auto_player">
                    {/* <img src={Auto} alt="Image" /> */}
                    <img
                                className="audio_button"
                                // style={{ float: "right" }}
                                src={audio_play}
                             
                              />
                  </div>

                  <Row>
                    <Col md={6} xs={12}>
                      <h4 className="left_space">VIDEO</h4>
                      <p>
                        There will be videos throughout the course. You will be
                        required to view these videos to get a more in depth
                        explanation of some of the important concepts in the
                        course. All of the videos have closed captioning, so
                        that you are able to follow along with the text.
                      </p>

                      <div
                        className="video_img"
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                      >
                        <img src={Video} alt="Image" />
                      </div>
                    </Col>

                    {/* <Col md={6} xs={12}>
                      <h4 className="left_space mt-md-0 mt-4">DROP DOWN MENUS</h4>
                      <p>
                        There will be drop down menus throughout the course,
                        indicated by a downward arrow or “v” symbol. The symbol
                        will look like this:
                      </p>

                      <div className="dropdown_img">
                        <img src={Dropdown} alt="Image" />
                      </div>
                    </Col> */}
                  </Row>

                  <div className="mt-md-5 mb-md-5 mt-3 mb-3 border_width_mob">
                    <img src={Border} alt="Image" />
                  </div>

                  <Row>
                    <Col md={6} xs={12}>
                      <h4
                        className="left_space"
                        data-aos="zoom-in-right"
                        data-aos-duration="2000"
                      >
                        NAVIGATING THE COURSE
                      </h4>

                      <p>
                        When you reach the end of a module, click the “Next”
                        button to move forward. If you have clicked the "Next"
                        button and it does not take you to the next module, you
                        most likely have not completed the time requirements for
                        that section. Please go over the information in the
                        module again and take your time to fully understand the
                        material. You must remain on this module until the timer
                        shows 0:00.
                      </p>
                    </Col>

                    <Col md={6} xs={12}>
                      <div
                        className="exercise_img"
                        data-aos="zoom-in-left"
                        data-aos-duration="2000"
                      >
                        <img src={Exercise} alt="Image" />
                      </div>
                    </Col>
                  </Row>

                  <div className="auto_player">
                    <Row>
                      <Col md={6} xs={6} className="text-center">
                        {/* <button className="yellow_btn_module">BACK</button> */}
                        <Button
                    variant="primary"
                    className="yellow_btn_module btnSign"                
                  >
                    Back
                  </Button>
                      </Col>

                      <Col md={6} xs={6} className="text-center">
                        {/* <button className="yellow_btn_module">NEXT</button> */}
                        <Button
                    variant="primary"
                    className="yellow_btn_module btnSign"
                  >
                    Next
                  </Button>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col md={9} xs={12}>
                      <p>
                        To navigate through your profile menu, you may use the
                        downward arrow button on the top right. <span style={{fontSize:"0.8em"}}>(Next to your profile picture, which is your initials.)</span>
                      </p>
                    </Col>

                    <Col md={3} xs={12} className="text-center">
                      <img
                        src={Arrow_Navigate}
                        className="arrow_nav"
                        alt="Image"
                      />
                    </Col>
                  </Row>

                  <div className="tools_menu">
                    <h5 className="mt-3 mb-5">
                      These additional tools are found at the menu on the top
                      right of the page:
                    </h5>
                    <div className="tool_content">
                      <Row>
                        <Col md={6} xs={12}>
                          <h6 data-aos="zoom-in-right" data-aos-duration="2000">
                            COURSE
                          </h6>

                          <p className="mb-3">
                            Click the "Course" button to view the Course page.
                            This is the page you arrive at when you log in to
                            the course. It will show your course progress, as a
                            percentage. You can choose to start or resume the
                            course from here.
                          </p>

                          <h6 data-aos="zoom-in-right" data-aos-duration="2000">
                            PROFILE
                          </h6>

                          <p className="mb-3">
                            Click the "Profile" button to view your personal
                            proﬁle information, account settings, and payment
                            receipts.
                          </p>

                          <h6 data-aos="zoom-in-right" data-aos-duration="2000">
                            REFERENCE
                          </h6>

                          <p className="mb-3">
                          Click the “Reference” button to get a personal 
                          link for referring family and friends to this course. 
                          When they sign up, you get $5 cashback!   
                          </p>
                          <p className="law_note">
                            Note: You must wait until the end of the course in
                            order to Refer a Friend.
                          </p>
                        </Col>

                        <Col md={6} xs={12}>
                          <h6 data-aos="zoom-in-left" data-aos-duration="2000">
                            LOGOUT
                          </h6>

                          <p className="mb-3">
                            Click the “Logout” button to log out of the course.
                          </p>

                          <div className="tool_user_detail">
                            <div className="tool_user_name">AA</div>

                            <ul className="">
                              <li>COURSE</li>

                              <li>PROFILE</li>

                              <li>REFERENCE</li>

                              <li>LOGOUT</li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="mt-md-5 mb-md-5 mt-3 mb-3 border_width_mob">
                    <img src={Border} alt="Image" />
                  </div>
                </div>

                <h2
                  className="mt-4 navigation_ins"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  PRINTABLE LEARNING MATERIALS
                </h2>
                <p>
                  At the top of each module, you will have the option to
                  download and print a reference guide that will cover the key
                  learning points of that module. We recommend that you keep
                  this guide as a reference that you can refer to in the future.
                  To download the guide, click on the button that looks like
                  this:
                </p>

                {/* <div className="learn_material_div text-center"> */}
                <div className="timeDiv learn_material_div text-center ">
                  {/* <button data-aos="zoom-in-right" data-aos-duration="2000">
                    LEARNING MATERIALS
                  </button> */}
                    <Button
                                  className="btn-lg learningMoreBtn"
                                  data-aos="zoom-in-right" data-aos-duration="2000"
                                >
                                  {/* <div
                                  className="timeDiv p-4"
                                    style={{
                                      display: "flex",
                                      lineHeight: "20px",
                                      alignItems:"center",
                                      justifyContent:"center"
                                    }}
                                  > */}
                                    <img src={downloadblue} className="pr-1" />{" "}
                                    <span>

                                    Learning Materials
                                    </span>
                                  {/* </div> */}
                                </Button>
                </div>

                <h2
                  className="mt-5 navigation_ins"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  course duration
                </h2>
                <p>
                  The DMV has required that you take at least 270 minutes to
                  complete the content in this course. Identity veriﬁcations and
                  time to take the module quizzes do not count toward this
                  requirement. There will be timers present throughout the
                  course to help make sure that you are spending the required
                  amount of time in each section. If you reach the end of a
                  section early, just go back and review the material in that
                  section for the remainder of the time.
                </p>

                <h2
                  className="mt-5 navigation_ins"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  COURSE QUIZZES
                </h2>
                <p>
                  At the end of each module, there will be a brief three-question quiz, 
                  that will help measure your understanding of
                  material covered. The quizzes are timed to allow for one
                  minute per question. That is the maximum amount of time
                  you have - you can complete the quiz sooner. You must pass
                  each quiz with a minimum score of 70% before you are allowed
                  to proceed to the next module. This means that you must answer
                  all of the questions correctly. You will have three attempts
                  to pass a quiz within the allotted time. If you fail the quiz
                  after three attempts, you will have to restart the course from
                  the beginning.
                </p>
              </div>
            </div>
          </Container>

          <div className="identity_method mt-5">
            <Container>
              <h3 data-aos="zoom-in-right" data-aos-duration="2000">
                IDENTITY VERIFICATION METHODS
              </h3>
              <p className="identity_para pl-1 mb-5">
                There are several identity veriﬁcation methods that we will use
                throughout the course to help us identify you:
              </p>

              <h4 data-aos="zoom-in-right" data-aos-duration="2000">
                PERSONAL SECURITY QUESTIONS
              </h4>

              <Row>
                <Col md={8} xs={12}>
                  <p className="security_para">
                  When you first registered for the course, you were asked 
                  a series of personal security questions. These answers were 
                  recorded and stored. Please make sure that you will remember your 
                  answers - to verify your identity, these questions will be asked 
                  again throughout the course, and each time that you log in to the course. 
                  You will have 45 seconds to answer each question and must answer
                  correctly to proceed with your course. If you fail three
                  attempts, you will be locked out of the course. You must
                  contact customer service to help resolve this issue.
                  </p>
                </Col>

                <Col md={4} xs={12}>
                  <div
                    className="car_img"
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                  >
                    <img src={Car} alt="Image" />
                  </div>
                </Col>
              </Row>
              <div className="mt-3 mb-3">
                <img src={White_Border} className="border_img" alt="Image" />
              </div>
            </Container>
          </div>

          <div className="audio_video mt-4">
            {/* <Container>
              <h4 data-aos="zoom-in-right" data-aos-duration="2000">
                TYPING VERIFICATION
              </h4>
              <p>
                We will validate your identity using your unique typing pattern.
                You will be required to provide a typing sample to validate your
                identity at random points during your course. This typing sample
                will likely not be related to the content of the course. The
                purpose of this typing sample is to validate your identity. The
                typing sample prompt will look like this:
              </p>

              <div className="typing_demo">
                <p>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry. Loren Ipsum has been the industry,s
                  standard dummy text ever since the 1500s, when an unknown
                  priter took a galley of the type and scrambled it to make a
                  type specimen book.
                </p>

                <p>Type the above text here</p>

                <div className="typing_textarea"></div>
                <span className="d-block">Submit</span>
              </div>

              <p>
                Each individual has a unique typing pattern based on the timings
                and durations of various key press events. We use
                state-of-the-art typing keystroke and biometrics analysis to
                store a baseline typing pattern that we then use to match new
                typing patterns and verify your identity.
              </p>

              <span className="validate_span">
                It is best to take the course using the same device.
              </span>

              <p>
                Please note that your typing pattern is dependent on the device
                you setup your typing verification on. Your phone typing will be
                diﬀerent from your desktop typing. For that reason, we strongly
                recommend you start and complete the course on the same type of
                device...
              </p>

              <h4
                className="mt-4 mb-3 process_text"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                THE PROCESS IS AS FOLLOWS:
              </h4>

              <div className="process">
                <ul className="p-0">
                  <li>
                    As you begin the course, you will submit a typing sample of
                    a few sentences.
                  </li>

                  <li>
                    Our system will analyze it and store a base typing pattern
                    unique to you. This typing pattern will be used to verify
                    your identity throughout the course.
                  </li>
                </ul>

                <div className="process_bg">
                  <ul className="p-0">
                    <li>
                      You will be asked to type in a typing sample throughout
                      the course. Our system will match the patterns of this
                      typing sample to the original sample you have submitted.
                    </li>

                    <li>
                      After successfully passing the typing verification, you
                      will be able to continue.
                    </li>
                  </ul>

                  <div className="mt-5 pt-md-0 pt-sm-5 text-center">
                    <img
                      src={White_Border}
                      className="border_img"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>
            </Container> */}

                     <Container>
              <h4 data-aos="zoom-in-right" data-aos-duration="2000">
                OTP VERIFICATION
              </h4>
              <p>
              Before you began your course, you enrolled your phone number to
              receive a One-Time PIN (OTP). As you progress through the course, 
              you will be prompted to choose between receiving your PIN via SMS or a phone call. 
              If you select SMS, a text message containing the PIN will be sent to the enrolled 
              phone number. If you choose the phone call option, you will 
              receive an automated call to your enrolled number that recites the PIN. 
              Once you receive the PIN, enter it into the dialog box and submit it to 
              complete the validation.
              </p>

              {/* <div className="typing_demo">
                <p>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry. Loren Ipsum has been the industry,s
                  standard dummy text ever since the 1500s, when an unknown
                  priter took a galley of the type and scrambled it to make a
                  type specimen book.
                </p>

                <p>Type the above text here</p>

                <div className="typing_textarea"></div>
                <span className="d-block">Submit</span>
              </div> */}

              <p>
              Please make sure that you do not close your browser window once you initiate 
              the validation! Closing the window will not stop the validation process and 
              will result in an immediate failure. You must enter the PIN within 60 seconds, 
              so keep your phone accessible and ready. If you do not complete the validation 
              within the time limit, it will result in a validation failure.

              </p>

              {/* <span className="validate_span">
                It is best to take the course using the same device.
              </span> */}

              {/* <p>
                Please note that your typing pattern is dependent on the device
                you setup your typing verification on. Your phone typing will be
                diﬀerent from your desktop typing. For that reason, we strongly
                recommend you start and complete the course on the same type of
                device...
              </p> */}
{/* 
              <h4
                className="mt-4 mb-3 process_text"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                THE PROCESS IS AS FOLLOWS:
              </h4> */}

              <div className="process">
                {/* <ul className="p-0">
                  <li>
                    As you begin the course, you will submit a typing sample of
                    a few sentences.
                  </li>

                  <li>
                    Our system will analyze it and store a base typing pattern
                    unique to you. This typing pattern will be used to verify
                    your identity throughout the course.
                  </li>
                </ul> */}

                <div className="process_bg">
                  {/* <ul className="p-0">
                    <li>
                      You will be asked to type in a typing sample throughout
                      the course. Our system will match the patterns of this
                      typing sample to the original sample you have submitted.
                    </li>

                    <li>
                      After successfully passing the typing verification, you
                      will be able to continue.
                    </li>
                  </ul> */}

                  {/* <div className="mt-5 pt-md-0 pt-sm-5 text-center">
                    <img
                      src={White_Border}
                      className="border_img"
                      alt="Image"
                    />
                  </div> */}
                </div>
              </div>
            </Container> 
          </div>

          <div className="main_content sample_para_type mt-5">
            <Container>
              {/* <h2
                className="mt-5 navigation_ins"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                WHAT IF I FAIL MY TYPING VERIFICATION SAMPLE?
              </h2>

              <p>
                If you fail one time, do not worry. We realize that problems can
                occur, and our system will give you an immediate chance to try
                again. If you fail three consecutive checkpoints, your course
                will be locked. You may be required to purchase a new course
                enrollment to restart from the beginning.
              </p>

              <p className="text-bold">
                Please contact customer service with any questions or concerns.
              </p> */}

              <h2
                className="mt-5 navigation_ins"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                DISABILITY STATEMENT
              </h2>

              <p>
                We do not discriminate based on language, ability to read, or
                other disabilities that learners may have that would otherwise
                prevent successful completion of the course. Learners who may
                not be able to read or write may take their tests orally when
                administered by a designated support representative. Other
                learner disabilities will be accommodated as needed.
              </p>

              <p>
                Learners with special needs should notify the course provider
                upon registering for the course, so that proper accomodations may
                be made for the learner in a timely manner.
              </p>

              <h2
                className="mt-5 navigation_ins"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                READY TO GET STARTED?
              </h2>

              <p>
                Now that you are more familiar with how to navigate this course,
                you are now ready to begin.
              </p>

              <p className="next_click">
                CLICK "NEXT" TO PROCEED WITH THE COURSE.
              </p>

              {/* <div className="readyto" data-aos="zoom-in"
      data-aos-duration="2000">
        <img src={Start} alt="Image" />

        <div className="border_img_ready">
          <img src={White_Border}  alt="Image" />
        </div>
      </div>  */}
            </Container>
          </div>
        </div>
      </Container>
    </div>
  );
};
