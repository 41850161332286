import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container, Card } from "react-bootstrap";
import under_con from "../../../../Images/module5/under_con.png";
import speeding_img from "../../../../Images/module5/speeding_img.png";
import drive from "../../../../Images/module5/drive.png";
import officer from "../../../../Images/module5/officer.png";
import Stop from "../../../../Images/module5/m54.png";
import Yield from "../../../../Images/module5/m55.png";
import Regulation from "../../../../Images/module5/m56.png";
import Warning from "../../../../Images/module5/m57.png";
import Railroad from "../../../../Images/module5/m58.png";
import WorkArea from "../../../../Images/module5/m59.png";
import Users from "../../../../Images/module5/users.mp4";
import LargeVehicle from "../../../../Images/module5/largevehicle.mp4";
import CellPhonesAndSafetyBeltsComponent from "./CellPhonesAndSafetyBeltsComponent";
import Slide1 from "../../../../Images/module5/slide1.jpg";
import Slide2 from "../../../../Images/module5/slide2.jpg";
import Slide3 from "../../../../Images/module5/slide3.jpg";
import Slide4 from "../../../../Images/module5/slide4.jpg";
import Slide5 from "../../../../Images/module5/slide5.jpg";
import Slide6 from "../../../../Images/module5/slide6.jpg";
import Slide7 from "../../../../Images/module5/slide7.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFive.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

const TRAFFIC_SIGNS = [
  {
    header: "STOP SIGN ",
    headerSpan: "(red with white letter)",
    img: Stop,
    details:
      "Come to a full stop, yield the right of way to vehicles and pedestrians in or approaching the intersection. Go when it is safe. You must come to a full stop before the stop line, if there is one.",
  },
  {
    header: "Yield Signs ",
    headerSpan: "(red and white with red letters)",
    img: Yield,
    details:
      "Slow down as you approach the intersection. Prepare to stop and yield the right of way to vehicles and pedestrians in or approaching the intersection. You must come to a full stop at a YIELD sign if traffic conditions require it. Approach with caution and be prepared to stop; otherwise, proceed with care.",
  },
  {
    header: "Regulation signs ",
    headerSpan: "(white with black and/or red letters or symbols)",
    img: Regulation,
    details:
      "These signs give you information about rules for traffic direction, lane use, turning, speed, parking, and other special requirements",
  },
  {
    header: "Warning signs ",
    headerSpan: "(yellow with black letters or sumbols)",
    img: Warning,
    details:
      "you are approaching an especially hazardous location or a place where there is a special rule. Be especially cautions when you see a warning sign.",
  },
  {
    header: "Railroad crossing ",
    headerSpan: "(Yellow with black letters 'RR' and 'X' symbol)",
    img: Railroad,
    details:
      "There is a railroad crossing ahead. you should use caution, and you may have to stop. Most buses and some trucks must stop rail road crossings, even when a train is not approaching and warning lights are not activated.",
  },
  {
    header: "Work area signs ",
    headerSpan: "(orange with black letters or symbols)",
    img: WorkArea,
    details:
      "People are working on or near the roadway, and traffic may be controlled by flag person. A work area speed limit of 35 MPH may be posted.",
  },
];

export const ModuleFivePart4 = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };
  return (
    <div>
      <Container>
        <div className="module5_course same_width_module mt-md-4 mb-md-4">
           <div className="container">
            <div className="mdu5_page1">
              <h2>Module 5 - Part 4</h2>
            </div>
          </div>
          <div className="mdu5_page9">
            <div className="drowsiness mdu5_page7">
              <div className="container">
                <div className="safe_zone">
                  <h2
                    class="aos-init aos-animate"
                    data-aos="slide-right"
                    data-aos-duration="3000"
                  >
                    FATIGUE AND DROWSINESS
                  </h2>
                </div>
                <p>
                  The likelihood of falling asleep at the wheel is more common
                  than most people realize. One out of five drivers admit to
                  having fallen asleep at the wheel at least once. Many other
                  sleepy drivers who deny falling asleep at the wheel may have
                  experienced microsleeping while driving.
                </p>

                <p>
                  Microsleep occurs when a drowsy driver is overtaken by
                  involuntary “naps” that last four to five seconds. It is
                  estimated that 100,000 reported crashes annually are the
                  result of drowsiness.
                </p>

                <p>
                  Many drivers don’t realize that they have no control over
                  whether they fall asleep. They also don’t realize that they
                  cannot predict when they are about to fall asleep. In a test
                  situation, nearly 80% of drivers thought that they could
                  predict when they were about to fall asleep behind the wheel;
                  they were proven wrong. This creates a false sense of security
                  in the sleepy driver.
                </p>

                <p>
                  Have you ever fallen asleep when you didn't even know you were
                  tired? In school? Watching television? Doing homework? When
                  did you realize that you'd been sleeping? How long were you
                  asleep?
                </p>
              </div>

              <div className="abillity">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  How does drowsiness impair driving ability?
                </h2>
                <p>
                  Visual misperception: The driver misinterprets what he or she
                  sees on the road.
                </p>
                <p>
                  Increased reaction time: The driver responds more slowly to
                  changing road or vehicle conditions.
                </p>
                <p>
                  Decreased attention span and reduced information processing:
                  The driver has difficulty concentrating on the road and
                  vehicle conditions, causing a diminished ability to process
                  the necessary information for making appropriate driver
                  decisions.
                </p>
                <p>
                  Diminished judgment: The driver has an increased likelihood of
                  making decisions based on poor judgement, including attempting
                  risky maneuvers, such as passing other vehicles without fully
                  surveying the situation.
                </p>
                <p>
                  Impaired problem-solving ability: The driver is less capable
                  of getting out of dangerous situations.
                </p>
                <p>
                  Decreased ability to control the vehicle: The driver may tend
                  to have less control of the vehicle, such as allowing it to
                  drift from lane to lane.
                </p>
                <div className="tips">
                  <div className="row">
                    <div className="col-md-4">
                      <img className="under_con" src={drive} alt="" srcset="" />
                    </div>

                    <div className="col-md-8">
                      <h3>
                        TIPS ON STAYING AWAKE <span> (Before you drive) </span>
                      </h3>
                      <p>
                        <b>BE REALISTIC:</b>
                        <br />
                        Rather than trying to cover a long distance in one shot,
                        plan on stopping for a short rest or an overnight stay.
                      </p>
                      <p>
                        <b>AVOID ALCOHOL AND OTHER DRUGS:</b>
                        <br />
                        Even one drink will make a slightly tired person
                        drowsier. Avoid any medicines, such as allergy pills,
                        that may induce drowsiness.
                      </p>
                      <p>
                        <b>TRAVEL RESTED:</b>
                        <br />
                        Get plenty of rest before your trip.
                      </p>
                      <p>
                        <b>RESPECT YOUR BODY CLOCK:</b> <br />
                        If you drive when you normally sleep, you’re fighting
                        your body’s natural rhythm. Avoid this, or make plans to
                        rest along the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mdu5_page10">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <div className="mdu5_p10_con">
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      TIPS FOR STAYING AWAKE <span>(On the road)</span>
                    </h2>
                    <p>
                      <span>Nap</span> - A short rest may be an effective remedy
                      to drowsiness. Find a safe place, such as a lit parking
                      lot of an open restaurant or store, or a designated rest
                      stop.
                    </p>

                    <p>
                      <span>Stay stimulated - </span> Listen to the radio (a
                      talk show may keep you more alert than music), open a
                      window, chew gum.
                    </p>

                    <p>
                      <span>Share the ride</span> - A travel partner can keep
                      you awake with conversation and give you a break from
                      driving.
                    </p>

                    <p>
                      <span>Take a walk</span> - Get out at a rest stop to take
                      a quick walk to the restroom, or just stroll around for
                      five minutes. Even a short break is helpful.
                    </p>

                    <p>
                      <span>Drink caffeine</span> - Caffeine can be helpful in
                      getting you briefly energized, but, remember that the
                      effects begin to wear off in an hour or so.
                    </p>

                    <p>
                      <span>Recognize the warning signs</span> - If your eyes
                      get droopy, your head nods down, your sight begins to
                      diminish, your attention to the driving tasks wanders, or
                      you can’t stop yawning, you are at risk.
                    </p>
                    <p>
                      <span>Deterioration of your driving</span> - If you drift
                      back and forth in your driving lane, inadvertently
                      tailgate other vehicles, or misread traffic signs, you are
                      at risk.
                    </p>
                  </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-2">
                  <div className="officer_img">
                    <img
                      src={officer}
                      alt=""
                      srcset=""
                      data-aos="slide-left"
                      data-aos-duration="3000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
